<template>
    <div v-if="filterDataSource" class="p-relative">

        <Toolbar
            :is-list="true"
            v-on:toolbar-button-click="onToolbarClick"
        >
            <!--Панель поиска-->
            <SearchPanel
                v-model="search"
                :has-filter="false"
            />
        </Toolbar>

        <FilterPanel
            v-if="filterDataSource"
            :dataSource="filterDataSource"
            v-bind:status.sync="collection"
            v-bind:substatus.sync="status"
        />

        <component
            :is="collection"
            :status="status"
            :search="search"
            ref="currentComponent"
        />

    </div>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import { httpAPI } from "@/api/httpAPI";

import Toolbar from '@/components/documents/Toolbar.vue';
import SearchPanel from '@/components/documents/SearchPanel.vue';
import FilterPanel from '@/components/documents/FilterPanel.vue';

import Adjusted from './HandledLists/Adjusted.vue';
import Executed from './HandledLists/Executed.vue';
import Inspected from './HandledLists/Inspected.vue';
import Signed from './HandledLists/Signed.vue';

export default {
    name: "HandledProxy",
    components: {
        Toolbar,
        SearchPanel,
        FilterPanel,

        "Documents.My.Adjusted": Adjusted,
        "Documents.My.Executed": Executed,
        "Documents.My.Inspected": Inspected,
        "Documents.My.Signed": Signed
    },
    computed: {
        collection: {
            get: function() {
                return this.$store.getters['correspondence/handled/getCollection'];
            },
            set: function(newValue) {
                this.$store.commit('correspondence/handled/SET_COLLECTION', newValue);
            }
        },
        status: {
            get: function() {
                return this.$store.getters['correspondence/handled/getStatus'];
            },
            set: function(newValue) {
                if (typeof this.$refs.currentComponent?.resetPage === "function")
                    this.$refs.currentComponent.resetPage();

                this.$store.commit('correspondence/handled/SET_STATUS', newValue);
            }
        },
        search: {
            get: function() {
                var searches = this.$store.getters['correspondence/handled/getSearches'];

                if (!searches) return "";
                
                return searches.find(x => x.collection == this.collection)?.value ?? "";
            },
            set: _.debounce(function(v) {
                if (typeof this.$refs.currentComponent?.resetPage === "function")
                    this.$refs.currentComponent.resetPage();

                this.$store.commit('correspondence/handled/PUSH_SEARCH', { collection: this.collection, value: v } );
            }, 500)
        }
    },
    data() {
        return {
            filterDataSource: null
        }
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        onToolbarClick(event, btn) {
            this[btn.Action]();
        },
        async loadCollectionData() {
            this.$eventBus.$emit('update_correspondents_counters');
            let response = await httpAPI({
                url: `api/actions/collection?type=${this.collection}`,
                method: 'GET'
            });

            if (response) {
                await this.loadFilters();
                this.$store.commit('documents/toolbar/SET_MENU', { menu: response.data.Payload.Data.Menu });
            }
        },
        async loadFilters() {
            let filterResponse = await httpAPI({
                url: `/api/correspondence/filter?collection=Handled`,
                method: 'GET'
            });

            if (filterResponse) {
                var permissions = (this.$store.getters['global/auth/getUserInfo'])?.Permissions ?? [];
                var filter = filterResponse.data.Payload.Filter.Items.find(x => x.Value == "Handled");

                if (filter && filter.Items) {
                    filter.Items.Items = filter.Items.Items?.filter(i => this.$_.difference(i.requires, permissions).length === 0);
                    this.filterDataSource = { Filter: filter.Items };
                }
            }
        },
        async Refresh() {
            await this.loadFilters();
            this.$refs.currentComponent.getData(true);
        }
    },
    watch: {
        collection : {
            handler () {
                this.loadCollectionData();
            }
        }
    },
    async created () {
        this.setOverlayVisible({ visible: true });
        try
        {
            await this.loadCollectionData();
        }
        finally
        {
            this.setOverlayVisible({ visible: false });
        }
    }
}
</script>